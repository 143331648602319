import { Redirect } from '@/components';
import { Authenticate } from '@/components/authenticate';
import { clearAuthCookie } from '@/helpers';
import { CodeRouter, NotFound, Terms } from '@/pages';
import Privacy from '@/pages/privacy';
import DiagnoseDone from '@diagnose/done';
import DiagnoseReport from '@diagnose/report';
import DiagnoseStart from '@diagnose/survey';
import SurveyChart from '@survey/chart';
import SurveyFeedback from '@survey/feedback';
import SurveyIndex from '@survey/index';
import SurveyNotAvailable from '@survey/not-available';
import SurveyNotFound from '@survey/not-found';
import SurveyReport from '@survey/report/survey-report';
import TeamReport from '@survey/report/team-report';
import SurveyShare from '@survey/share';
import SurveyThanks from '@survey/thanks';
import { Route, Routes } from 'react-router';
import SurveyReportGrantAccess from './survey/grant-access';

const Layout = () => {
  return (
    <>
      <Routes>
        <Route
          path='/diagnose/survey/:surveyId'
          index
          element={<Authenticate component={<DiagnoseStart />} />}
        />
        <Route
          path='/diagnose/survey/:surveyId/done'
          index
          element={<Authenticate component={<DiagnoseDone />} />}
        />
        <Route
          path='/sign-out'
          index
          element={
            <Authenticate
              component={
                <Redirect to='/' beforeRedirect={() => clearAuthCookie()} />
              }
              unauthorized={<Redirect to='/' />}
            />
          }
        />
        <Route
          path='/diagnose/:diagnoseId'
          index
          element={<Authenticate component={<DiagnoseReport />} />}
        />
        <Route path='/reports/survey/:id' index element={<SurveyReport />} />
        <Route
          path='/reports/project/:projectId/team/:teamId'
          element={<TeamReport />}
        />
        <Route
          path='/survey/:id/grantAccess'
          element={<Authenticate component={<SurveyReportGrantAccess />} />}
        />
        <Route path='/survey/start' element={<SurveyIndex />} />
        <Route path='/survey/feedback' element={<SurveyFeedback />} />
        <Route path='/survey/share' element={<SurveyShare />} />
        <Route path='/survey/not-available' element={<SurveyNotAvailable />} />
        <Route path='/survey/not-found' element={<SurveyNotFound />} />
        <Route path='/survey/:id' element={<SurveyChart />} />
        <Route path='/feedback/thanks' element={<SurveyThanks />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/:code' element={<CodeRouter />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  );
};

export default Layout;

export function getCookies() {
  const cookies = document.cookie.split(';').map((x) => x.trim());

  const res: Record<string, string> = {};

  for (const item of cookies) {
    const parts = item.split('=');
    res[parts[0]] = parts.length > 1 ? parts[1] : '';
  }

  return res;
}

export function getCookie(cname: string): string | undefined {
  const name = cname + '=';
  const ca = decodeURIComponent(document.cookie).split(';');

  for (const c of ca) {
    if (c.trimStart().indexOf(name) === 0) {
      return c.trimStart().substring(name.length, c.length);
    }
  }
}

export function getAuthCookie() {
  const authCookie = getCookie('.app.auth');
  if (!authCookie) {
    return;
  }

  return JSON.parse(atob(authCookie)) as AuthenticationData;
}

export function clearAuthCookie() {
  document.cookie = `.app.auth=;domain=${process.env.REACT_APP_COOKIE_DOMAIN};path=/`;
}

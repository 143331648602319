import { useTranslation } from '@/components';
import { UserRoles, VerificationTimeoutCodes } from '@/enums';
import {
  checkEmailValidity,
  fontWeights,
  getAuthCookie,
  getVerificationTimeout,
  redirectToUserspace,
  setVerificationTimeout,
  webClient
} from '@/helpers';
import {
  AuthButtonBack,
  AuthPanel,
  AuthPanelHeader,
  AuthPanelHeader2,
  AuthSocialButton
} from '@auth/styleds';
import { useAppContext } from '@contexts/app-context';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

enum Steps {
  Email = 1,
  Password = 2,
  Code = 3,
  CodeVerify = 4
}

const SignIn = () => {
  const { setMainClasses } = useAppContext();
  const navigate = useNavigate();
  const t = useTranslation('Authentication.SignIn');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<FormError>(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<FormError>(false);
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState<'text' | 'password'>(
    'password'
  );
  const [step, setStep] = useState<Steps>(Steps.Email);
  const [remainingTime, setRemainingTime] = useState(60);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState<FormError>(false);

  let timeoutId: any;

  const getExpireTime = () => {
    return getVerificationTimeout(VerificationTimeoutCodes.AuthVerify) || 0;
  };
  const getTimeout = () =>
    getExpireTime() - Math.round(new Date().getTime() / 1000);
  const clearTimer = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };
  const startTimer = () => {
    clearTimer();

    timeoutId = setInterval(() => {
      setRemainingTime(getTimeout());

      if (getTimeout() < 0) {
        clearTimer();
      }
    }, 1000);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    switch (e.target.name) {
      case 'email':
        setEmail(e.target.value);
        setEmailError(false);
        break;

      case 'password':
        setPassword(e.target.value);
        setPasswordError(false);
        break;

      default:
        throw new Error('Invalid form');
    }
  };

  const login = async () => {
    let hasError = false;
    if (!checkEmailValidity(email)) {
      hasError = true;
      setEmailError('$Authentication.InvalidEmail');
    }

    if (password.trim().length < 1) {
      hasError = true;
      setPasswordError('$Authentication.RequiredPassword');
    }

    if (hasError || emailError !== false || passwordError !== false) {
      return;
    }

    setLoading(true);
    try {
      await webClient.api.auth.basic.login(
        {
          email,
          password
        },
        {
          authHandled: true,
          loadingHandled: true
        }
      );
      setLoading(false);

      redirectToUserspace(navigate);
    } catch (exp) {
      setLoading(false);
      setPasswordError('IncorrectEmailOrPassword');
    }
  };

  const togglePassword = () =>
    setPasswordType(passwordType === 'password' ? 'text' : 'password');

  const magicLink = async () => {
    setLoading(true);
    try {
      await webClient.api.auth.basic.resend({
        email
      });

      setVerificationTimeout(VerificationTimeoutCodes.AuthVerify, 60);
      setStep(Steps.Code);
      startTimer();
    } finally {
      setLoading(false);
    }
  };

  const submit = async () => {
    if (code.length !== 5) {
      setCodeError('VerificationCodeIsWrong');
      return;
    }

    setLoading(true);

    try {
      await webClient.api.auth.basic.verify({
        email,
        code
      });

      setVerificationTimeout(VerificationTimeoutCodes.AuthVerify, 5);
      setLoading(false);
      setStep(Steps.CodeVerify);
      setTimeout(() => {
        redirectToUserspace(navigate);
      }, 5e3);
    } catch (exp) {
      setLoading(false);
      setCodeError('VerificationCodeIsWrong');
    }
  };

  useEffect(() => {
    setMainClasses();

    const user = getAuthCookie();
    if (
      !user ||
      (user.roles.length === 1 && user.roles[0] === UserRoles.Guest)
    ) {
      return;
    }

    redirectToUserspace(navigate);
  }, []);

  if (step === Steps.CodeVerify) {
    return (
      <AuthPanel textAlign='center'>
        <Typography
          variant='h3'
          sx={{
            mt: 2,
            fontWeight: fontWeights.bold
          }}
        >
          {t('$Authentication.VerificationCompleted')}
        </Typography>
        <Typography variant='h4' sx={{ mt: 1, mb: 'auto' }}>
          {t('$Authentication.WeWillRedirectToYourPersonalSpace', {
            time:
              remainingTime > 1
                ? t('$XSeconds', { x: remainingTime })
                : t('$1Second')
          })}
        </Typography>
      </AuthPanel>
    );
  }

  if (step === Steps.Code) {
    return (
      <AuthPanel>
        <AuthButtonBack onClick={() => setStep(Steps.Email)} />
        <AuthPanelHeader>
          {t('$Authentication.MakingSureItIsYou')}
        </AuthPanelHeader>
        <Typography>
          {t('$Authentication.PleaseEnterYourVerificationCode')}
        </Typography>
        <InputLabel sx={{ mt: 3 }} htmlFor='recover_code'>
          {t('$Authentication.VerificationCode')}
        </InputLabel>
        <OutlinedInput
          error={codeError !== false}
          type='text'
          fullWidth
          defaultValue={code}
          placeholder={t('$Authentication.VerificationCodeHint')}
          name='code'
          id='recover_code'
          key='recover_code'
          onChange={(e) => {
            setCode(e.target.value);
            setCodeError(false);
          }}
        />
        <FormHelperText error={codeError !== false}>
          {codeError !== false ? t(codeError) : ''}
        </FormHelperText>
        <Box fontSize={14} mt={1}>
          {t('$Authentication.DidntGetTheCode')}
          <Button
            variant='text'
            onClick={magicLink}
            disabled={remainingTime > 0}
            sx={{ p: 0, fontSize: 'inherit' }}
          >
            {t('$Authentication.Resend')}
          </Button>{' '}
          {remainingTime > 0 &&
            t('$Authentication.ResendItIn', {
              time:
                remainingTime > 1
                  ? t('$XSeconds', { x: remainingTime })
                  : t('$1Second')
            })}
        </Box>
        <Button
          fullWidth
          sx={{ mt: 2, fontSize: 18 }}
          variant='contained'
          disabled={loading || code.trim().length < 5}
          onClick={submit}
        >
          {loading ? <CircularProgress color='inherit' /> : t('$Submit')}
        </Button>
        <Box mt={16 / 12} fontSize={14} fontWeight={fontWeights.regular}>
          I have a
          <Button
            variant='text'
            onClick={() => setStep(Steps.Password)}
            sx={{
              fontSize: 'inherit',
              fontWeight: 'inherit',
              p: 0,
              ml: 0.5
            }}
          >
            {t('$Authentication.Password').toLowerCase()}
          </Button>
        </Box>
      </AuthPanel>
    );
  }

  if (step === Steps.Password) {
    return (
      <AuthPanel>
        <AuthButtonBack onClick={() => setStep(Steps.Code)} />
        <AuthPanelHeader2 variant='h2'>{email}</AuthPanelHeader2>
        <InputLabel sx={{ mt: 4 }} htmlFor='signin_password'>
          {t('$Authentication.Password')}
        </InputLabel>
        <OutlinedInput
          error={passwordError !== false}
          type={passwordType}
          fullWidth
          defaultValue={password}
          placeholder={t('$Authentication.PasswordHint')}
          name='password'
          id='signin_password'
          key='signin_password'
          onChange={handleChange}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton onClick={togglePassword}>
                {passwordType === 'password' ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText error={passwordError !== false}>
          {passwordError !== false ? t(passwordError) : ''}
        </FormHelperText>
        <Button
          variant='text'
          component={Link}
          to={
            window.hasReturnUrl
              ? `/recover-password/?returnUrl=${window.returnUrlEncoded}`
              : '/recover-password/'
          }
          sx={{
            p: 0,
            fontSize: 12,
            fontWeight: fontWeights.regular,
            mt: 8 / 12
          }}
        >
          {t('$Authentication.ForgotPassword')}
        </Button>
        <Button
          fullWidth
          sx={{ mt: 2, fontSize: 18 }}
          variant='contained'
          disabled={
            loading || passwordError !== false || password.trim() === ''
          }
          onClick={login}
        >
          {loading ? (
            <CircularProgress color='inherit' />
          ) : (
            t('$Authentication.Login')
          )}
        </Button>
      </AuthPanel>
    );
  }

  return (
    <AuthPanel>
      {window.hasReturnUrl ? (
        <AuthPanelHeader variant='h2'>{t('NeedLogin')}</AuthPanelHeader>
      ) : (
        <AuthPanelHeader variant='h2'>{t('LoginToUnlimited')}</AuthPanelHeader>
      )}
      <InputLabel sx={{ mt: 4 }} htmlFor='signin_email'>
        {t('$Authentication.Email')}
      </InputLabel>
      <OutlinedInput
        error={emailError !== false}
        type='email'
        fullWidth
        defaultValue={email}
        placeholder={t('$Authentication.EmailHint')}
        name='email'
        id='signin_email'
        key='signin_email'
        onChange={handleChange}
      />
      <FormHelperText error={emailError !== false}>
        {emailError !== false ? emailError : ''}
      </FormHelperText>
      <Button
        fullWidth
        sx={{ mt: 2, fontSize: 18 }}
        variant='contained'
        disabled={!email || !checkEmailValidity(email)}
        onClick={magicLink}
      >
        {t('GetYourCode')}
      </Button>
      <Box
        mt={1}
        mb={32 / 12}
        display='flex'
        flexDirection='row'
        alignItems='center'
      >
        {t('DontHaveAccount')}
        <Button
          variant='text'
          component={Link}
          to={
            window.hasReturnUrl
              ? `/register/?returnUrl=${window.returnUrlEncoded}`
              : '/register/'
          }
          sx={{
            fontSize: 16,
            p: 0,
            fontWeight: fontWeights.regular,
            ml: 0.5
          }}
        >
          {t('$Authentication.Register')}
        </Button>
      </Box>
      <Divider />
      <Typography
        variant='h3'
        mt={1}
        fontSize={18}
        fontWeight={fontWeights.semiBold}
      >
        {t('$Authentication.OrContinueWith')}
      </Typography>
      <Grid container columnSpacing={5} rowSpacing={0} mt={32 / 12}>
        <Grid item xs={4}>
          <AuthSocialButton
            fullWidth
            color='secondary'
            href={`${process.env.REACT_APP_API}/v1/auth/google/${document.location.search}`}
            startIcon={<img src='/assets/images/google.svg' alt='Google' />}
          >
            Google
          </AuthSocialButton>
        </Grid>
        {/* <Grid item xs={4}>
          <AuthSocialButton
            fullWidth
            color='secondary'
            href={`${process.env.REACT_APP_API}/v1/auth/apple/${document.location.search}`}
            startIcon={<img src='/assets/images/apple.svg' alt='Google' />}
          >
            Apple
          </AuthSocialButton>
        </Grid> */}
      </Grid>
    </AuthPanel>
  );
};

export default SignIn;
